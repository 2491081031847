import React from 'react'
import Classes from './Home.module.css'
import { AnimateTopToBottom } from '../../Animations/AnimateTopToBottom'

export default function DiscordBtn() {
    return (
        <AnimateTopToBottom duration={0.3} delay={0.2} >
            <a href='https://discord.gg/qjBNzS7GpW' target='_blank' className={Classes.discord} >
                <svg id="fi_4945914" enable-background="new 0 0 512 512" fill='white' height="30" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path id="Icon" d="m133.408 346.261c95.35 37.211 176.493 44.459 268.445-13.461-.674 1.011-18.863 27.958-66.695 41.768 10.105 13.811 23.916 29.642 23.916 29.642 30.653 0 60.632-8.758 86.568-25.6 20.211-13.474 31.663-36.716 29.305-60.968-4.042-41.432-13.811-81.853-28.968-120.589-19.2-50.526-65.347-85.558-118.905-90.611-4.716-.337-8.084-.337-10.105-.337l-5.389 5.389c61.642 17.516 92.295 44.8 92.968 45.811-94.653-47.832-206.484-48.505-301.811-2.021 0 0 30.316-29.979 97.347-45.811l-4.042-4.042c-7.074 0-13.811.674-20.884 1.684-49.516 8.421-90.947 42.442-108.8 89.263-15.495 40.421-25.6 82.863-29.305 125.979-2.021 22.905 8.758 45.474 27.621 58.611 24.926 16.505 54.568 25.263 84.547 25.263 0 0 12.126-15.832 24.253-29.979-45.474-13.474-64-40.421-64.337-41.432l8.558 4.37c5.116 2.614 10.361 4.982 15.713 7.071zm52.866-29.293c-21.895-.674-39.074-19.2-38.4-41.432.674-20.884 17.516-37.726 38.4-38.4 21.895.674 39.074 19.2 38.4 41.432-1.011 20.885-17.516 37.727-38.4 38.4zm137.431 0c-21.895-.674-39.074-19.2-38.4-41.432.674-20.884 17.516-37.726 38.4-38.4 21.895.674 39.074 19.2 38.4 41.432-.673 20.885-17.516 37.727-38.4 38.4z"></path></svg>
                <h5>Join the community!</h5>
            </a>
        </AnimateTopToBottom>
    )
}
