import React, { useState } from 'react';
import Classes from './Home.module.css';
import Video from './Video';

export default function ShortVideos() {
    const [Videos] = useState([
        { link: 'https://autosauctionsbucket.s3.amazonaws.com/Testing/short/1.mp4' },
        { link: 'https://autosauctionsbucket.s3.amazonaws.com/Testing/short/2.mp4' },
        { link: 'https://autosauctionsbucket.s3.amazonaws.com/Testing/short/3.mp4' },
        { link: 'https://autosauctionsbucket.s3.amazonaws.com/Testing/short/4.mp4' },
        { link: 'https://autosauctionsbucket.s3.amazonaws.com/Testing/short/5.mp4' },
        { link: 'https://autosauctionsbucket.s3.amazonaws.com/Testing/short/6.mp4' },
    ]);

    return (
        <div className={Classes.shortVideosCon}>
            {Videos.map((val, ind) => (
                <Video key={ind} val={val} />
            ))}
        </div>
    );
}
