import React from 'react'
import Classes from './Home.module.css'
import VideoSlider from './VideoSlider'
import ReviewSlider from './ReviewSlider'
import ShortVideos from './ShortVideos'
import DiscordBtn from './DiscordBtn'
import { AnimateTopToBottom } from '../../Animations/AnimateTopToBottom'
import { Animate1 } from '../../Animations/Animate1'
const bg = '/hero-bg.jpeg'
const binaryBg = '/binary-bg.svg'

export default function Home() {
    return (
        <>
            <DiscordBtn />
            <section className={Classes.home} >
                <div id='home' className={Classes.section1} >
                    <AnimateTopToBottom duration={0.5} delay={0.3} >
                        <div className={Classes.miniHeading} >
                            <h5>Client results: 35M Views, $40K Revenue</h5>
                        </div>
                    </AnimateTopToBottom>
                    <AnimateTopToBottom duration={0.5} delay={0.4} >
                        <h5 className={Classes.title} >
                            BE One of the <br />
                            <span> 1% Creators </span>
                        </h5>
                    </AnimateTopToBottom>
                    <AnimateTopToBottom duration={0.5} delay={0.5} >
                        <h5 className={Classes.miniTitle} >
                            ZRax Media is a video editing agency,
                            We have generated +30M views for our clients, x5 their views in their first few months,
                            <br /> And we can help you do the same and reach your maximum potential on social media!
                        </h5>
                    </AnimateTopToBottom>
                    <AnimateTopToBottom duration={0.5} delay={0.6} >
                        <button className={Classes.mainBtn} >
                            <h5>Get Started Now</h5>
                        </button>
                    </AnimateTopToBottom>
                </div>
                <Animate1 delay={0.3} duration={0.5} y={30} >
                    <div id='demo' className={Classes.section2}  >
                        <h5 className={Classes.pageTitle} > Recent Work - Long Form </h5>
                        <VideoSlider />
                    </div>
                </Animate1>
                <img
                    src={bg}
                    className={Classes.gradientBackground}
                />
            </section>
            <section id='recent-work' className={Classes.section3} >
                <Animate1 delay={0.3} duration={0.5} y={10} >
                    <h5 className={Classes.pageTitle} > Recent Work - Short Form </h5>
                </Animate1>
                <ShortVideos />
            </section>
            <Animate1 delay={0.7} duration={0.5} y={10} >
                <section id='reviews' className={Classes.section4} >
                    <h5 className={Classes.pageTitle} > Reviews </h5>
                    <ReviewSlider />
                    <img
                        src={binaryBg}
                        className={Classes.binaryBg}
                    />
                </section>
            </Animate1>
            <Animate1 delay={0.3} duration={0.5} y={10} >
                <section id='contact' className={Classes.footer} >
                    <div>
                        <div className={Classes.flexAlign} >
                            <img
                                src='/agency.png'
                                className={Classes.logo}
                            />
                            <div>
                                <h5 className={Classes.footerLogoTxt} >Zrax Media</h5>
                                <h5 className={Classes.emailText} >zraxmedia@gmail.com</h5>
                            </div>
                        </div>
                        <h5 className={Classes.copyrightText} style={{maxWidth:'50rem'}} >
                            ZRax Media is a video editing agency. We help creators x5 their views in their first few months working with us to reach their maximum potential on social media and reach more people, Which equals them making more money!
                            We specialize in creating short form videos for your personal brand, company, or YouTube channel...
                            We also create long form videos, which our team specializes in too, We will help increase your audience retention and views.
                        </h5>
                        <h5 className={Classes.copyrightText} >2024 © Zrax Media, All rights reserved.</h5>
                        <div className={Classes.flexAlign} >
                            <a href='https://x.com/zraxmedia' target='_blank' >
                                <svg
                                    height='25px'
                                    cursor='pointer'
                                    version="1.1"
                                    id="fi_733579"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 512 512"
                                    style={{ enableBackground: "new 0 0 512 512" }}
                                    xmlSpace="preserve"
                                >
                                    <path
                                        style={{ fill: "#03A9F4" }}
                                        d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
        c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
        c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
        c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
        c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
        c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
        C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
        C480.224,136.96,497.728,118.496,512,97.248z"
                                    />
                                </svg>
                            </a>
                            <a href='https://www.instagram.com/zrax_media' target='_blank' >
                                <svg
                                    height='25px'
                                    cursor='pointer'
                                    version="1.1"
                                    id="fi_174855"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 551.034 551.034"
                                    style={{ enableBackground: 'new 0 0 551.034 551.034' }}
                                    xmlSpace="preserve"
                                >
                                    <g>
                                        <linearGradient
                                            id="SVGID_1_"
                                            gradientUnits="userSpaceOnUse"
                                            x1="275.517"
                                            y1="4.57"
                                            x2="275.517"
                                            y2="549.72"
                                            gradientTransform="matrix(1 0 0 -1 0 554)"
                                        >
                                            <stop offset="0" style={{ stopColor: '#E09B3D' }}></stop>
                                            <stop offset="0.3" style={{ stopColor: '#C74C4D' }}></stop>
                                            <stop offset="0.6" style={{ stopColor: '#C21975' }}></stop>
                                            <stop offset="1" style={{ stopColor: '#7024C4' }}></stop>
                                        </linearGradient>
                                        <path
                                            style={{ fill: 'url(#SVGID_1_)' }}
                                            d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722c0,90.516,73.64,164.156,164.156,164.156h222.722
        c90.516,0,164.156-73.64,164.156-164.156V164.156C551.033,73.64,477.393,0,386.878,0z M495.6,386.878
        c0,60.045-48.677,108.722-108.722,108.722H164.156c-60.045,0-108.722-48.677-108.722-108.722V164.156
        c0-60.046,48.677-108.722,108.722-108.722h222.722c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878
        L495.6,386.878z"
                                        ></path>
                                        <linearGradient
                                            id="SVGID_2_"
                                            gradientUnits="userSpaceOnUse"
                                            x1="275.517"
                                            y1="4.57"
                                            x2="275.517"
                                            y2="549.72"
                                            gradientTransform="matrix(1 0 0 -1 0 554)"
                                        >
                                            <stop offset="0" style={{ stopColor: '#E09B3D' }}></stop>
                                            <stop offset="0.3" style={{ stopColor: '#C74C4D' }}></stop>
                                            <stop offset="0.6" style={{ stopColor: '#C21975' }}></stop>
                                            <stop offset="1" style={{ stopColor: '#7024C4' }}></stop>
                                        </linearGradient>
                                        <path
                                            style={{ fill: 'url(#SVGID_2_)' }}
                                            d="M275.517,133C196.933,133,133,196.933,133,275.516s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516
        S354.101,133,275.517,133z M275.517,362.6c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083
        c48.095,0,87.083,38.988,87.083,87.083C362.6,323.611,323.611,362.6,275.517,362.6z"
                                        ></path>
                                        <linearGradient
                                            id="SVGID_3_"
                                            gradientUnits="userSpaceOnUse"
                                            x1="418.31"
                                            y1="4.57"
                                            x2="418.31"
                                            y2="549.72"
                                            gradientTransform="matrix(1 0 0 -1 0 554)"
                                        >
                                            <stop offset="0" style={{ stopColor: '#E09B3D' }}></stop>
                                            <stop offset="0.3" style={{ stopColor: '#C74C4D' }}></stop>
                                            <stop offset="0.6" style={{ stopColor: '#C21975' }}></stop>
                                            <stop offset="1" style={{ stopColor: '#7024C4' }}></stop>
                                        </linearGradient>
                                        <circle
                                            style={{ fill: 'url(#SVGID_3_)' }}
                                            cx="418.31"
                                            cy="134.07"
                                            r="34.15"
                                        ></circle>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </section>
            </Animate1>
        </>
    )
}
