import React, { useRef, useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

import Classes from './Home.module.css'
import ReactPlayer from 'react-player'
import './VideoSlider.css'

const RightArrowSliderIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z" /></svg>
)
const LeftArrowSliderIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM271 135c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-87 87 87 87c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L167 273c-9.4-9.4-9.4-24.6 0-33.9L271 135z" /></svg>
)

export default function ReviewSlider() {

    const swiperRef = useRef(null);
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
                swiperRef.current.swiper.slideNext();
            }
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={20}
            ref={swiperRef}
            autoplay={{ delay: 5000 }}
            loop={true}
            navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            }}
            modules={[Navigation]}
            className="mySwiper"
        >
            <SwiperSlide className='swiperSlide' >
                <div className={Classes.sliderElem2} >
                    <div className={Classes.profile} >
                        <img src='/reviews/Alan.png' />
                    </div>
                    <h5 className={Classes.nameTitle} > Alan </h5>
                    <h5 className={Classes.reviewText} > "Thank you! This is very good video! I’ve worked with several video editors in the past, but this agency stands out for its professionalism and attention to detail. They delivered a polished final product that exceeded my expectations, all within a tight deadline. Highly recommend!" </h5>
                    <div className={Classes.quotesCon} >
                        <svg id="fi_9915682" height="20" width="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g><path d="m12 4h-9c-1.6543 0-3 1.3457-3 3v9c0 1.6543 1.3457 3 3 3h4.03662l-1.51807 7.80908c-.05713.29346.02002.59668.20996.82715s.47266.36377.77149.36377h3.23975c.86963 0 1.64258-.56885 1.90576-1.39258l2.91113-6.90527c.0166-.03955.03076-.08008.04199-.12109.2666-.95801.40137-1.94874.40137-2.94434v-9.63672c0-1.6543-1.3457-3-3-3z"></path><path d="m29 4h-9c-1.6543 0-3 1.3457-3 3v9c0 1.6543 1.3457 3 3 3h4.03711l-1.51855 7.80908c-.05762.29346.01953.59668.20996.82715.18945.23047.47265.36377.77148.36377h3.24023c.87012 0 1.64258-.56885 1.90527-1.39307l2.91113-6.90479c.0166-.03955.03027-.08008.04199-.12109.26661-.95898.40138-1.9497.40138-2.94433v-9.63672c0-1.6543-1.3457-3-3-3z"></path></g></svg>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide className='swiperSlide' >
                <div className={Classes.sliderElem2} >
                    <div className={Classes.profile} >
                        <img src='/reviews/cjstein.png' />
                    </div>
                    <h5 className={Classes.nameTitle} > CJ Stein </h5>
                    <h5 className={Classes.reviewText} > "That's sick bro 🔥 The team took our vision and turned it into something truly special. Their creative input was invaluable, and they were always open to feedback, ensuring that the final video was exactly what we wanted. We’ll definitely be working with them again!" </h5>
                    <div className={Classes.quotesCon} >
                        <svg id="fi_9915682" height="20" width="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g><path d="m12 4h-9c-1.6543 0-3 1.3457-3 3v9c0 1.6543 1.3457 3 3 3h4.03662l-1.51807 7.80908c-.05713.29346.02002.59668.20996.82715s.47266.36377.77149.36377h3.23975c.86963 0 1.64258-.56885 1.90576-1.39258l2.91113-6.90527c.0166-.03955.03076-.08008.04199-.12109.2666-.95801.40137-1.94874.40137-2.94434v-9.63672c0-1.6543-1.3457-3-3-3z"></path><path d="m29 4h-9c-1.6543 0-3 1.3457-3 3v9c0 1.6543 1.3457 3 3 3h4.03711l-1.51855 7.80908c-.05762.29346.01953.59668.20996.82715.18945.23047.47265.36377.77148.36377h3.24023c.87012 0 1.64258-.56885 1.90527-1.39307l2.91113-6.90479c.0166-.03955.03027-.08008.04199-.12109.26661-.95898.40138-1.9497.40138-2.94433v-9.63672c0-1.6543-1.3457-3-3-3z"></path></g></svg>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide className='swiperSlide' >
                <div className={Classes.sliderElem2} >
                    <div className={Classes.profile} >
                        <img src='/reviews/nick.png' />
                    </div>
                    <h5 className={Classes.nameTitle} > Nick </h5>
                    <h5 className={Classes.reviewText} > "Brooooooooooooo! this video is so fu##in good. From the initial consultation to the final delivery, the experience was smooth and hassle-free. They communicated clearly throughout the process and delivered a high-quality video that really captured the essence of our brand. A+ service!" </h5>
                    <div className={Classes.quotesCon} >
                        <svg id="fi_9915682" height="20" width="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g><path d="m12 4h-9c-1.6543 0-3 1.3457-3 3v9c0 1.6543 1.3457 3 3 3h4.03662l-1.51807 7.80908c-.05713.29346.02002.59668.20996.82715s.47266.36377.77149.36377h3.23975c.86963 0 1.64258-.56885 1.90576-1.39258l2.91113-6.90527c.0166-.03955.03076-.08008.04199-.12109.2666-.95801.40137-1.94874.40137-2.94434v-9.63672c0-1.6543-1.3457-3-3-3z"></path><path d="m29 4h-9c-1.6543 0-3 1.3457-3 3v9c0 1.6543 1.3457 3 3 3h4.03711l-1.51855 7.80908c-.05762.29346.01953.59668.20996.82715.18945.23047.47265.36377.77148.36377h3.24023c.87012 0 1.64258-.56885 1.90527-1.39307l2.91113-6.90479c.0166-.03955.03027-.08008.04199-.12109.26661-.95898.40138-1.9497.40138-2.94433v-9.63672c0-1.6543-1.3457-3-3-3z"></path></g></svg>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide className='swiperSlide' >
                <div className={Classes.sliderElem2} >
                    <div className={Classes.profile} >
                        <img src='/reviews/sund4r.png' />
                    </div>
                    <h5 className={Classes.nameTitle} > Sund4r </h5>
                    <h5 className={Classes.reviewText} > "Damn this is cool, i actually liked the tranistion at the first it was cool! We came to them with a rough concept, and they brought it to life in ways we hadn’t even imagined. The editing was seamless, and the final product was dynamic and engaging. Couldn’t be happier with the results!" </h5>
                    <div className={Classes.quotesCon} >
                        <svg id="fi_9915682" height="20" width="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g><path d="m12 4h-9c-1.6543 0-3 1.3457-3 3v9c0 1.6543 1.3457 3 3 3h4.03662l-1.51807 7.80908c-.05713.29346.02002.59668.20996.82715s.47266.36377.77149.36377h3.23975c.86963 0 1.64258-.56885 1.90576-1.39258l2.91113-6.90527c.0166-.03955.03076-.08008.04199-.12109.2666-.95801.40137-1.94874.40137-2.94434v-9.63672c0-1.6543-1.3457-3-3-3z"></path><path d="m29 4h-9c-1.6543 0-3 1.3457-3 3v9c0 1.6543 1.3457 3 3 3h4.03711l-1.51855 7.80908c-.05762.29346.01953.59668.20996.82715.18945.23047.47265.36377.77148.36377h3.24023c.87012 0 1.64258-.56885 1.90527-1.39307l2.91113-6.90479c.0166-.03955.03027-.08008.04199-.12109.26661-.95898.40138-1.9497.40138-2.94433v-9.63672c0-1.6543-1.3457-3-3-3z"></path></g></svg>
                    </div>
                </div>
            </SwiperSlide>
            {/* <div className="swiper-button-prev">
                <LeftArrowSliderIcon />
            </div>
            <div className="swiper-button-next">
                <RightArrowSliderIcon />
            </div> */}
        </Swiper>
    )
}
