import React from 'react'
import Classes from './Header.module.css'
import { AnimateTopToBottom } from '../../Animations/AnimateTopToBottom';
const logo = '/agency.png'

export default function Header() {
    const scrollToElement = (elementId) => {
        const targetElement = document.getElementById(elementId);

        if (targetElement) {
            const offset = 50;
            const targetPosition = targetElement.offsetTop + offset;

            window.scroll({
                top: targetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <AnimateTopToBottom duration={0.2} delay={0.1} >
            <div className={Classes.header} >
                <div className={Classes.flexAlign} >
                    <a href='/' >
                        <img
                            src={logo}
                            className={Classes.logo}
                        />
                    </a>
                </div>
                <ul>
                    <li className={Classes.elem} onClick={() => { scrollToElement('home') }} > <h5>Home</h5> </li>
                    <li className={Classes.elem} onClick={() => { scrollToElement('demo') }} > <h5>Demo</h5> </li>
                    <li className={Classes.elem} onClick={() => { scrollToElement('recent-work') }} > <h5>Recent Work</h5> </li>
                    <li className={Classes.elem} onClick={() => { scrollToElement('reviews') }} > <h5>Reviews</h5> </li>
                </ul>
                <div>
                    <button className={Classes.contactBtn} onClick={() => { scrollToElement('contact') }} > <h5>Contact</h5> </button>
                </div>
            </div>
        </AnimateTopToBottom>
    )
}
