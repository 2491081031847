import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import Classes from './Home.module.css';
import ReactPlayer from 'react-player';
import './VideoSlider.css';

const RightArrowSliderIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z" />
    </svg>
);

const LeftArrowSliderIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM271 135c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-87 87 87 87c-9.4 9.4-9.4 24.6 0 33.9s-24.6 9.4-33.9 0L167 273c-9.4-9.4-9.4-24.6 0-33.9L271 135z" />
    </svg>
);

export default function VideoSlider() {
    const swiperRef = useRef(null);
    const [playingIndex, setPlayingIndex] = useState(null);

    const handlePlay = (index) => {
        setPlayingIndex(index);
        if (swiperRef.current) {
            swiperRef.current.swiper.allowSlideNext = false;
            swiperRef.current.swiper.allowSlidePrev = false;
        }
    };

    const handlePause = () => {
        setPlayingIndex(null);
        if (swiperRef.current) {
            swiperRef.current.swiper.allowSlideNext = true;
            swiperRef.current.swiper.allowSlidePrev = true;
        }
    };

    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={20}
            ref={swiperRef}
            autoplay={{ delay: 10000 }}
            loop={true}
            navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            }}
            modules={[Navigation]}
            className="mySwiper"
        >
            {[1, 2, 3].map((num, index) => (
                <SwiperSlide key={num} className='swiperSlide'>
                    <div className={Classes.sliderElem}>
                        {playingIndex !== index ? (
                            <div>
                                <img
                                    src={`/videos/long/${num}-photo.png`}
                                    alt="Video thumbnail"
                                    className={Classes.bigvideoThumbnail}
                                />
                                <svg
                                    version="1.1"
                                    id="fi_109197"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 512 512"
                                    style={{ enableBackground: 'new 0 0 512 512' }}
                                    xmlSpace="preserve"
                                    className={Classes.playSvg}
                                    onClick={() => handlePlay(index)}
                                >
                                    <path
                                        d="M256,0C114.617,0,0,114.615,0,256s114.617,256,256,256s256-114.615,256-256S397.383,0,256,0z M344.48,269.57l-128,80
                                        c-2.59,1.617-5.535,2.43-8.48,2.43c-2.668,0-5.34-0.664-7.758-2.008C195.156,347.172,192,341.82,192,336V176
                                        c0-5.82,3.156-11.172,8.242-13.992c5.086-2.836,11.305-2.664,16.238,0.422l128,80c4.676,2.93,7.52,8.055,7.52,13.57
                                        S349.156,266.641,344.48,269.57z"
                                    />
                                </svg>
                            </div>
                        ) : (
                            <ReactPlayer
                                url={`https://autosauctionsbucket.s3.amazonaws.com/Testing/${num}.mp4`}
                                controls
                                playing={playingIndex === index}
                                loop={false}
                                muted={false}
                                onPlay={() => handlePlay(index)}
                                onPause={handlePause}
                                onEnded={handlePause}
                            />
                        )}
                    </div>
                </SwiperSlide>
            ))}

            <div className="swiper-button-prev">
                <LeftArrowSliderIcon />
            </div>
            <div className="swiper-button-next">
                <RightArrowSliderIcon />
            </div>
        </Swiper>
    );
}
