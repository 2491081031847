import React, { useRef, useEffect } from 'react';
import Classes from './Home.module.css';
import { Animate1 } from '../../Animations/Animate1';

export default function Video({ val }) {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);

    return (
        <Animate1 delay={0.2} duration={0.5} y={30}>
            <div className={Classes.shortVideoElem} style={{ position: 'relative' }}>
                <video
                    ref={videoRef}
                    width="100%"
                    height="auto"
                    style={{ borderRadius: '15px' }}
                    controls
                    loop
                    muted
                    autoPlay
                >
                    <source src={val.link} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Animate1>
    );
}
